import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBdZCtV0mn_O-gg3LmTJEB75JcE2O94Amw",
  authDomain: "cryptomineclub-f673d.firebaseapp.com",
  databaseURL: "https://cryptomineclub-f673d-default-rtdb.firebaseio.com",
  projectId: "cryptomineclub-f673d",
  storageBucket: "cryptomineclub-f673d.appspot.com",
  messagingSenderId: "722881808684",
  appId: "1:722881808684:web:d405853cddcadebb7f0489"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()