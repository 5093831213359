import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ConfirmInvest from "./ConfirmInvest";
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
function Deposit() {
    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const [amount, setamount] = useState("")
    const [coin, setcoin] = useState("")
    const [wallet, setwallet] = useState("")
    const [CoinInfo, setCoinInfo] = useState("")


    const [image, setimage] = useState(null);
    const [url, seturl] = useState("");
    const [progress, setprogress] = useState(0);
    const [imagepresnt, setimagepresent] = useState(false);
    const [prevfile, setprevFile] = useState("")
    const [proceed, setproceed] = useState(false)

    const confirmdeposit = async () => {
        if (amount == "" || CoinInfo == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No Amount or Coin was Selected!',
            })
        } else {
            setproceed(true)
        }
    }

    const SetCoinInfo = (value) => {
        if (value == 1) {
            // bitcoin
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Bitcoin as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })



            setCoinInfo({
                name: "Bitcoin",
                address: "bc1qhskwy2a5a3tw0nzt3gehqnk2cv7f8rkyt00zmn",
                img: "https://img.icons8.com/color/48/000000/bitcoin--v1.png"
            })
        } else if (value == 2) {
            //ethereum
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Ethereum as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })

            setCoinInfo({
                name: "Ethereum",
                address: "0x28b7A65BFF4D49FBd2A38b5a3A2C9E437B09921a",
                img: "https://img.icons8.com/ios/452/ethereum.png"
            })

        } else if (value == 3) {
            //dodge
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Doge as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })
            setCoinInfo({
                name: "Doge",
                address: "DKyxqhwdTzgmUATBGfskT62VviAch5Lboq",
                img: "https://img.icons8.com/ios-filled/344/dogecoin.png"
            })

        } else if (value == 4) {
            //tron
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Tron as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })
            setCoinInfo({
                name: "Tron",
                address: "TXeeDBDvcinzwsYEcrqyHkX9yvuFuw1oqd",
                https: "https://img.icons8.com/cotton/344/tron.png"
            })

        } else if (value == 5) {
            //usdterc20
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Usdt ERC20 as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })
            setCoinInfo({
                name: "Usdt ERC20",
                address: "0x28b7A65BFF4D49FBd2A38b5a3A2C9E437B09921a",
                https: "https://img.icons8.com/cotton/344/tether--v1.png"
            })

        } else if (value == 6) {
            //usdtTrc20
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected USDT TRC20 as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })
            setCoinInfo({
                name: "USDT TRC20",
                address: "TXeeDBDvcinzwsYEcrqyHkX9yvuFuw1oqd",
                https: "https://img.icons8.com/color/344/tether--v2.png"
            })

        } else if (value == 7) {
            //usdtTrc20
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'You Selected Litecoin as Payment Method',
                showConfirmButton: false,
                timer: 1500
            })
            setCoinInfo({
                name: "Litecoin",
                address: "ltc1qf78rpyk7rs8kstxp06cr92fu859208u7agvwwd",
                https: "https://img.icons8.com/color/344/tether--v2.png"
            })

        }
    }


    const copyAlert = () => {
        navigator.clipboard.writeText(CoinInfo.address);
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `${CoinInfo.name} Address Was Copied Please Proceed To Payment`,
            showConfirmButton: false,
            timer: 1500
        })
    }


    const handleChange = (e) => {
        if (e.target.files[0]) {
            setimage(e.target.files[0]);
            setimagepresent(true);
            setprevFile(URL.createObjectURL(e.target.files[0]))
        }
    };

    const handleUpload = () => {
        if (!imagepresnt) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No Image Was Selected!',
            })
            return
        }
        //check wether amt is empty or file is not selected
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setprogress(progress);
            },
            (error) => {
                console.log(error);
            },
            () => {
                storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then((url) => {
                        seturl(url);
                        updatehistory(url);
                        setprogress(0);
                        setimagepresent(false)
                        // sendAdminMaiil()

                        Swal.fire(
                            'Succesful Transaction!',
                            'Your account will be credited once the payment is recieved.',
                            'success'
                        )
                        // alert("we will we credit your balance shortly");
                        setimage(null);
                        navigate("/dashboard")
                    });
            }
        );
    };

    const sendAdminMaiil = async () => {
        var templateParams = {
            to_name: userdetails.fullname,
            message:
                ` <div style="margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none;
               mso-line-height-rule: exactly; font-family: arial,'helvetica neue', helvetica, sans-serif; line-height: 21px; color: #333333;
               font-size: 14px;">You have initiated a Deposit Request on your account. The status of this transaction is <strong>PENDING</strong>.Upon
               Confirmation from Our System your account will be credited with $${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} worth of ${CoinInfo.name}
              </div>`,

            user_email: userdetails.email,
            notification: "Deposit"
        };

        // setloading(false)
        //handleClick1()

        await emailjs
            .send(
                "service_mj5xm0a",
                "template_6mp70jg",
                templateParams,
                "amB0f3bGs0j4iMtaI"
            )
            .then(
                function (response) {
                    // setloading(false)
                },
                function (err) {
                    // setloading(false)
                    console.log(err)
                    emailjs
                        .send(
                            "service_zlt5z21",
                            "template_8n42sty",
                            templateParams,
                            "XEMxltyUxYlmyVoyr"
                        )
                        .then(
                            function (response) {
                                setloading(false)
                            },
                            function (err) {
                                setloading(false)
                                alert("FAILED...", err);
                                console.log(err)
                            }
                        );
                }
            );
    }


    // Create Admin Deposit  Create it as A FireBase Document 
    // {userid,email,firebaseTime,transaction, details}


    const CreatUserObj = async (obj) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        let uobj = {
            fullname: userdetails.fullname,
            email: userdetails.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userid: userids,
            info: obj

        };

        const userdetail1 = await db
            .collection("deposits")
            .add(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };
    const updatehistory = async (urls) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        CreatUserObj({
            image: urls,
            date: Date.now(),
            amt: amount,
            mode: "Deposit",
            coin: CoinInfo.name
        })
        const increment = firebase.firestore.FieldValue.increment(parseInt(amount));
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            Totaldeposit: increment,
            Deposithistory: firebase.firestore.FieldValue.arrayUnion({
                image: urls,
                date: Date.now(),
                amt: amount,
                mode: "Deposit",
                coin: CoinInfo.name
            }),
        });
    };





    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")
    }

    const navigate = useNavigate();

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)

        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    navigate("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .onSnapshot((function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloading(false)
                } else {
                    console.log("No such document!");
                }
            })
            )
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    return (
        <div>
            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu" data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/deposit" className="nk-menu-link"><span className="nk-menu-text">Deposit</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Deposit History</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/mining" className="nk-menu-link"><span className="nk-menu-text">Mining plans</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Withdrawals</span></Link></li>

                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Withdrawal history</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                <div className="dropdown-inner">
                                                </div>
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="kyc-app wide-sm m-auto">
                                        <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                                            <div className="nk-block-head-content text-center">
                                                <h2 className="nk-block-title fw-normal">Funds Your Account</h2>
                                                <div className="nk-block-des">
                                                    <p>You have a total of USD 0 in your wallet available for withdrawal. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-block">
                                            <div className="card card-bordered">
                                                <div className="nk-kycfm">
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">01</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Personal Details</h5>
                                                            <p className="sub-title">Your simple personal information required for
                                                                identification</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <div className="nk-kycfm-note"><em className="icon ni ni-info-fill" data-toggle="tooltip" data-placement="right" title="Tooltip on right" />
                                                            <p>Please type carefully and fill out the form with your personal
                                                                details.</p>
                                                        </div>
                                                        <div className="row g-4">

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Username <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input type="text" className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">02</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Your Deposit Informaton</h5>
                                                            <p className="sub-title">details about your Deposit.</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <div className="nk-kycfm-note"><em className="icon ni ni-info-fill" data-toggle="tooltip" data-placement="right" title="Tooltip on right" />
                                                            <p>Your can’t edit these details once you submitted the form.</p>
                                                        </div>
                                                        <div className="row g-4">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Amount <span className="text-danger">*</span></label></div>
                                                                    <div className="form-control-group"><input id="amount" type="text" onChange={(e) => { setamount(e.target.value) }} className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <div className="form-label-group"><label className="form-label">Wallet Address <span className="text-danger">*</span> </label></div>
                                                                    <div className="form-control-group"><input id="wallet_address" type="text" onChange={(e) => { setwallet(e.target.value) }} className="form-control form-control-lg" /></div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-head">
                                                        <div className="nk-kycfm-count">03</div>
                                                        <div className="nk-kycfm-title">
                                                            <h5 className="title">Crypto Wallet Type</h5>
                                                        </div>
                                                    </div>
                                                    <div className="nk-kycfm-content">
                                                        <ul className="nk-kycfm-control-list g-3">
                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(1) }} type="radio" name="id-proof" id="bitcoin" data-title="Bitcoin" defaultChecked /><label className="nk-kycfm-label" htmlFor="bitcoin"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Bitcoin</span></label></li>


                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(2) }} type="radio" name="id-proof" id="eth" data-title="eth" /><label className="nk-kycfm-label" htmlFor="eth"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Ethereum
                                                                </span></label></li>

                                                            {/* <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(3) }} type="radio" name="id-proof" id="doge" data-title="doge" /><label className="nk-kycfm-label" htmlFor="doge"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Dogecoin
                                                                </span></label></li> */}

                                                            {/* 
                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(4) }} type="radio" name="id-proof" id="tron" data-title="tron" /><label className="nk-kycfm-label" htmlFor="tron"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Tron
                                                                </span></label></li> */}

                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(5) }} type="radio" name="id-proof" id="usdterc20" data-title="usdterc20" /><label className="nk-kycfm-label" htmlFor="usdterc20"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">USDT (Tether)
                                                                </span></label></li>

                                                            {/* <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(6) }} type="radio" name="id-proof" id="usdtTrc20" data-title="usdtTrc20" /><label className="nk-kycfm-label" htmlFor="usdtTrc20"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text">Tether TRC20
                                                                </span></label></li>


                                                            <li className="nk-kycfm-control-item"><input className="nk-kycfm-control" onClick={() => { SetCoinInfo(7) }} type="radio" name="id-proof" id="litecoin" data-title="litecoin" /><label className="nk-kycfm-label" htmlFor="litecoin"><span className="nk-kycfm-label-icon">
                                                                <div className="label-icon">
                                                                </div>
                                                            </span><span className="nk-kycfm-label-text"> Litecoin
                                                                </span></label></li> */}


                                                        </ul>
                                                        <h6 className="title">To avoid delays when depositing funds, Please make
                                                            sure you check below:</h6>
                                                        <ul className="list list-sm list-checked">
                                                            <li>You have the available funds.</li>
                                                            <li>The Amount provided are accurate</li>
                                                            <li>Personal Details match current profile data on your account</li>
                                                        </ul>
                                                    </div>
                                                    <div className="nk-kycfm-footer">
                                                        <div className="form-group">
                                                            <div className="custom-control custom-control-xs custom-checkbox"><input type="checkbox" className="custom-control-input" id="tc-agree" /><label className="custom-control-label" htmlFor="tc-agree">I Have Read The <a href="/terms-and-conditions.php" target="_blank">Terms Of
                                                                Condition</a> And <a href="/privacy-and-policy.php" target="_blank">Privacy Policy</a></label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-control-xs custom-checkbox"><input type="checkbox" className="custom-control-input" id="info-assure" /><label className="custom-control-label" htmlFor="info-assure">All The Personal Information I Have
                                                                Entered Is Correct.</label></div>
                                                        </div>
                                                        <div className="text-danger mt-2 mb-2" id="error-container">
                                                            <div className="text-success" id="success-container" />
                                                        </div>
                                                        <div className="nk-kycfm-action pt-2"><button onClick={confirmdeposit} type="button" id="withdraw" className="btn btn-lg btn-primary">Proceed To Deposit</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                    {
                        proceed && (
                            <div className="nk-block">
                                <div className="card card-bordered">
                                    <div className="card-inner">
                                        <div className="row gy-gs">
                                            <div className="col-12">
                                                <div className="bg-success text-white p-3">
                                                    Please pay the sum of<b> ${amount}</b> of {CoinInfo.name} equivalent as of today into the wallet address below
                                                    and await transaction confirmation, we would let you know when this investment starts through
                                                    your valid email.
                                                    <br />
                                                    <br />
                                                    <div className="nk-refwg-url">
                                                        <div className="form-control-wrap">
                                                            <div className="form-clip clipboard-init" data-clipboard-target="#refUrl" onClick={copyAlert} data-success="Copied" data-text="Copy Address"><em className="clipboard-icon icon ni ni-copy" /> <span className="clipboard-text">Copy address</span></div>
                                                            <div className="form-icon"><em className="icon ni ni-link-alt" /></div>
                                                            <input type="text" className="form-control copy-text" id="refUrl" defaultValue={CoinInfo.address} />
                                                        </div>
                                                    </div>

                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="fileUpload"
                                                            onChange={handleChange}
                                                        />
                                                        <label
                                                            className="custom-file-label"
                                                            htmlFor="fileUpload"
                                                        >
                                                            upload proof of payment
                                                        </label>
                                                    </div>



                                                    {imagepresnt && (
                                                        <img src={prevfile} className="previmg" />
                                                    )}

                                                    {imagepresnt && (
                                                        <>

                                                            {/* <div className="nk-opt-reset" onClick={props.handleUpload}><a className="reset-opt-setting">upload</a></div> */}
                                                            <a onClick={handleUpload} className="btn btn-lg btn-primary " >Click To Confirm Deposit</a>

                                                        </>
                                                    )}

                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }




























                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020 <a href="#">Crypto-mineclub Investment Management Company</a></div>
                            </div>
                            <div className="nk-footer-links">
                                <ul className="nav nav-sm">
                                    {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6><a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Deposit