import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'

function Dashboard() {
    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)
    const [profits, setprofits] = useState(0)
    const [mininigProfits, setmininigProfits] = useState(0)
    const [FreePlanProfits, setFreePlanProfits] = useState(0)
    const [freeplanCountDown, setfreeplanCountDown] = useState("")
    const [miningCountDownTimer, setminingCountDownTimer] = useState("0")

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    function cleanDate(d) {
        var date = new Date(d);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        console.log("Date: " + date.getDate() +
            "/" + (months[date.getMonth() + 1]) +
            "/" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds());
        return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
            planduecheck(userdetails)
            planduecheckMining(userdetails)
            Freeplancheck(userdetails)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/login");
                }
            });
        }
    }, []);


    const fetchuserdata = (userid) => {
        var docRef = db.collection("users").doc(userid);
        docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                    planduecheck(doc.data())
                    planduecheckMining(doc.data())
                    Freeplancheck(doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/login");
    };

    function addHoursToDate(date, hours) {
        return new Date(new Date(date).setHours(date.getHours() + hours));
    }



    /////////////////////////////
    // do plan due check for minig too
    const ActivateFreeMining = () => {
        Swal.fire({
            title: 'Do you want to activate the free mining',
            text: "you will make a total profit of $700 after 7 Days of Mining",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes continue'
        }).then((result) => {
            if (result.isConfirmed) {
                let myDate = new Date();
                const d2 = addHoursToDate(myDate, (7 * 24)).getTime()
                const subscription = {
                    currentPlan: "Free Plan",
                    dueAmount: 700,
                    dueDate: d2,
                    dateSubscribed: new Date().getTime(),
                };
                var userid = f.auth().currentUser;
                var userids = userid.uid;
                var washingtonRef = db.collection("users").doc(userids);
                washingtonRef
                    .update({
                        freeplan: subscription
                    }).then(function () {
                        Swal.fire(
                            'Success!',
                            'Free Plan Has Been Activated.',
                            'success'
                        )
                    })
            }
        })
    }
    const Freeplancheck = (info) => {
        const FreeplanInterval = setInterval(() => {
            const d1 = new Date();
            if (info.freeplan != null) {
                if (d1.getTime() >= info.freeplan.dueDate) {
                    // show withdrwal fee button
                    console.log("youir withdrawal is ready")

                } else if (d1.getTime() < info.freeplan.dueDate) {
                    var today = new Date();
                    var total = info.freeplan.dueDate - info.freeplan.dateSubscribed;
                    var progress = today - info.freeplan.dateSubscribed;

                    console.log(Math.round(progress / total * 100) + "%");
                    const currentprofit = ((progress / total * 100) * info.freeplan.dueAmount) / 100
                    const secs = ((info.freeplan.dueDate - today.getTime()) / 1000)
                    setfreeplanCountDown(secs)
                    console.log(secs)
                    // console.log(date.toString());

                    console.log(currentprofit)
                    //intrestprogress(currentprofit)
                    setFreePlanProfits(currentprofit)
                    console.log(info.freeplan.dueDate - d1.getTime());
                    const planprogress =
                        ((info.freeplan.dueDate - d1.getTime()) /
                            info.freeplan.dueDate) *
                        100;
                    console.log(planprogress);
                    let date = new Date(info.freeplan.dueDate);
                    console.log(date.toString());
                }
            } else {
                console.log(info.email);
            }
        }, 1000);
    };
    const MinigWithdrawalfee = () => {
        Swal.fire({
            title: 'Withdrawal Fees Payment',
            text: "You will make a payment of $500 to withdraw your $700 mining earnings",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes continue'
        }).then((result) => {
            if (result.isConfirmed) {
                const d1 = new Date();
                if (d1.getTime() < userdetails.freeplan.dueDate) {
                    Swal.fire(
                        'Your Mining Rigs Are Still Running.',
                        `Your withdrawal will bne raedy in ${calculateTime(freeplanCountDown)} `,
                        'success'
                    )

                } else if (d1.getTime() >= userdetails.freeplan.dueDate) {
                    alert("show wallets")
                    Swal.fire(
                        'bc1qlqtd80mdg3v2ujyj4ngths4xaegpxhps024egy',
                        'Pay $500  to the above Bitcoin wallet address to confirm Mining rigs withdrawal',
                        'question'
                    )
                }

            }
        })
    }
    const calculateTime = (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;

    }





    // const [miningCountDownTimer, setminingCountDownTimer] = useState("")
    const planduecheckMining = (info) => {
        const d1 = new Date();
        if (info.currentMining != null) {
            if (d1.getTime() >= info.currentMining.dueDate) {
                const newBal =
                    parseInt(info.balance) +
                    parseInt(info.currentMining.dueAmount);
                updateUserBalanceandSub(newBal, info.currentMining.dueAmount);
                console.log(info.currentMining.dueDate);
            } else if (d1.getTime() < info.currentMining.dueDate) {
                const intervalId = setInterval(() => {
                    var today = new Date();
                    var total = info.currentMining.dueDate - info.currentMining.dateSubscribed;
                    var progress = today - info.currentMining.dateSubscribed;

                    console.log(Math.round(progress / total * 100) + "%");
                    const currentprofit = ((progress / total * 100) * info.currentMining.dueAmount) / 100
                    const secs = ((info.currentMining.dueDate - today.getTime()) / 1000)
                    setminingCountDownTimer(secs)

                    //intrestprogress(currentprofit)
                    setmininigProfits(currentprofit)
                    console.log(info.currentMining.dueDate - d1.getTime());
                    const planprogress =
                        ((info.currentMining.dueDate - d1.getTime()) /
                            info.currentMining.dueDate) *
                        100;
                    console.log(planprogress);
                    let date = new Date(info.currentMining.dueDate);
                    console.log(date.toString());
                }, 1000);

            }
        } else {
            console.log(info.email);
        }
    };


    const planduecheck = (info) => {
        const d1 = new Date();
        if (info.currentSubscription != null) {
            if (d1.getTime() >= info.currentSubscription.dueDate) {
                const newBal =
                    parseInt(info.balance) +
                    parseInt(info.currentSubscription.dueAmount);
                updateUserBalanceandSub(newBal, info.currentSubscription.dueAmount);
                console.log(info.currentSubscription.dueDate);
            } else if (d1.getTime() < info.currentSubscription.dueDate) {
                var today = new Date();
                var total = info.currentSubscription.dueDate - info.currentSubscription.dateSubscribed;
                var progress = today - info.currentSubscription.dateSubscribed;

                console.log(Math.round(progress / total * 100) + "%");
                const currentprofit = ((progress / total * 100) * info.currentSubscription.dueAmount) / 100
                console.log(currentprofit)
                intrestprogress(currentprofit)
                setprofits(currentprofit)
                console.log(info.currentSubscription.dueDate - d1.getTime());
                const planprogress =
                    ((info.currentSubscription.dueDate - d1.getTime()) /
                        info.currentSubscription.dueDate) *
                    100;
                console.log(planprogress);
                let date = new Date(info.currentSubscription.dueDate);
                console.log(date.toString());
            }
        } else {
            console.log(info.email);
        }
    };


    const intrestprogress = (bal) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
        washingtonRef
            .update({
                totalearnings: bal,
            })
            .then(function () {
                console.log("Document successfully updated!");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata(userids);
    };

    const updateUserBalanceandSub = (bal, addedbal) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        washingtonRef
            .update({
                balance: bal,
                currentSubscription: null,
                totalearnings: addedbal,
            })
            .then(function () {
                console.log("Document successfully updated!");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata(userids);
    };

    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")
    }




    return (
        <div>

            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header-wrap">
                                <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                <div className="nk-header-menu " data-content="headerNav">
                                    <div className="nk-header-mobile">
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-menu-trigger mr-n2"><a onClick={setnav} className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                    </div>
                                    <ul className="nk-menu nk-menu-main">
                                        <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/deposit" className="nk-menu-link"><span className="nk-menu-text">Deposit</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Deposit History</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/mining" className="nk-menu-link"><span className="nk-menu-text">Mining plans</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Withdrawals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Withdrawal history</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                        <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                    </ul>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav">
                                        <li className="dropdown notification-dropdown">
                                            <a className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s2">
                                                <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a >Mark All as Read</a></div>
                                                <div className="dropdown-body">
                                                    <div className="nk-notification">
                                                        <table className="table table-ulogs">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="tb-col-time">
                                                                        <span className="overline-title">Activity</span>
                                                                    </th>
                                                                    <th className="tb-col-ip">
                                                                        <span className="overline-title">Details</span>
                                                                    </th>
                                                                </tr>
                                                            </thead><thead>
                                                                <tr>
                                                                    <td className="tb-col-os">Authentication</td>
                                                                    <td className="tb-col-ip">
                                                                        <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                    </td>
                                                                </tr><tr>
                                                                </tr></thead></table>
                                                    </div>
                                                    <div className="dropdown-foot center"><a >View All</a></div>
                                                </div>
                                            </div></li>
                                        <li className="hide-mb-sm"><a ><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                        <li className="dropdown user-dropdown order-sm-first">
                                            <a className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="user-toggle">
                                                    <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                    <div className="user-info d-none d-xl-block">
                                                        <div className="user-status user-status-verified">verified</div>
                                                        <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light ">
                                                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                    <div className="user-card">
                                                        <div className="user-avatar"><span>OS</span></div>
                                                        <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                        <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                    </div>
                                                </div>
                                                <div className="dropdown-inner user-account-info">
                                                    <h6 className="overline-title-alt">Account Balance</h6>
                                                    <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                    <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                </div>
                                                <div className="dropdown-inner">
                                                    {/* <ul className="link-list">
                                                        <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                        <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                        <li><Link to="/login"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                    </ul> */}
                                                </div>
                                                <div className="dropdown-inner">
                                                    <ul className="link-list">
                                                        <li><Link to="/"> <em className="icon ni ni-signout" /><span>Sign out</span></Link></li>
                                                    </ul>

                                                    <ul className="link-list">
                                                        <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head">
                                        <div className="nk-block-between-md g-3">
                                            <div className="nk-block-head-content">
                                                <div className="nk-block-head-sub"><span>Welcome!</span></div>
                                                <div className="align-center flex-wrap pb-2 gx-4 gy-3">
                                                    <div>
                                                        <h2 className="nk-block-title fw-normal">{userdetails.firstname}  {userdetails.lastname} </h2>
                                                    </div>
                                                    <div><Link to="/investmentplans" className="btn btn-white btn-light">My Plans <em className="icon ni ni-arrow-long-right ml-2" /></Link></div>
                                                </div>
                                                <div className="nk-block-des">
                                                    <p>At a glance summary of your investment account. Have fun!</p>
                                                </div>
                                            </div>
                                            <div className="nk-block-head-content d-none d-md-block">
                                                <div className="nk-slider nk-slider-s1">
                                                    <div className="slider-init" data-slick="{&quot;dots&quot;: true, &quot;arrows&quot;: false, &quot;fade&quot;: true}">
                                                        <div className="slider-item">
                                                            <div className="nk-iv-wg1">
                                                                <div className="nk-iv-wg1-sub sub-text">No Plans</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="slider-dots" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="nk-block">
                       <div class="nk-news card card-bordered">
                          <div class="card-inner">
                             <div class="nk-news-list">
                                <a class="nk-news-item" > */}
                                    {/* <div class="nk-news-icon"><em class="icon ni ni-card-view"></em></div> */}
                                    {/* <div class="nk-news-text">
                                      <p>Do you know the latest update of 2019? <span> A overview of our is now available on YouTube</span></p>
                                      <em class="icon ni ni-external"></em>
                                   </div> */}
                                    {/* </a>
                             </div>
                          </div>
                       </div>
                    </div> */}
                                    <div className="nk-block">
                                        <div className="row gy-gs">
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-dark card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Available Balance <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount"> {userdetails.balance}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-dark card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Mining Balance <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount"> {mininigProfits}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-dark card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Investment Balance <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount"> {profits}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-dark card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Newbies Bonus <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount">{userdetails.lockedbalance}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-dark card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Refferal Bonus <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount">{userdetails.referralearn}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card is-s1 card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Total Invested <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                {
                                                                    userdetails.totalearnings && (
                                                                        <div className="nk-iv-wg2-amount">  USD {Number.parseFloat(userdetails.balance).toFixed(2)}</div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-4">
                                                <div className="nk-wg-card is-s3 card card-bordered">
                                                    <div className="card-inner">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Total Profits <em className="icon ni ni-info" /></h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                {
                                                                    userdetails.totalearnings && (
                                                                        <div className="nk-iv-wg2-amount">  USD {Number.parseFloat(profits).toFixed(2)}</div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="row gy-gs">



                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card card card-bordered h-100">
                                                    <div className="card-inner h-100">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Your networth</h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount ui-v2">{userdetails.balance}</div>
                                                                <ul className="nk-iv-wg2-list">
                                                                    <li><span className="item-label">Referral Bonus</span><span className="item-value">{userdetails.referralearn} </span></li>
                                                                    <li><span className="item-label">Invested Funds</span><span className="item-value">{userdetails.balance} </span></li>
                                                                    <li className="total"><span className="item-label">Total</span><span className="item-value">0</span></li>
                                                                </ul>
                                                            </div>
                                                            <div className="nk-iv-wg2-cta"><Link to="/withdrawal" className="btn btn-primary btn-lg btn-block">Withdraw Funds</Link>
                                                                <Link to="/investmentplans" className="btn btn-trans btn-block">Re-invest Funds</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* ask for $500 withdrawal fee if plan expires in one week and user decides to withdraw */}
                                            {
                                                userdetails.freeplan == null && (
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="nk-wg-card card card-bordered h-100">
                                                            <div className="card-inner h-100">
                                                                <div className="nk-iv-wg2">
                                                                    <div className="nk-iv-wg2-title">
                                                                        <h6 className="title">Mining Free Plan <em className="icon ni ni-info text-primary" /></h6>
                                                                    </div>
                                                                    <div className="nk-iv-wg2-cta">
                                                                        <a className="btn btn-primary btn-lg btn-block" onClick={ActivateFreeMining}>Click to Activate</a>
                                                                        <div className="cta-extra">$700 earnings in one week <a className="link link-dark">Activate</a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                userdetails.freeplan != null && (
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="nk-wg-card card card-bordered h-100">
                                                            <div className="card-inner h-100">
                                                                <div className="nk-iv-wg2">
                                                                    <div className="nk-iv-wg2-title">
                                                                        <h6 className="title">Mining Free Plan <em className="icon ni ni-info text-primary" />{calculateTime(freeplanCountDown)}</h6>
                                                                    </div>
                                                                    <div className="nk-iv-wg2-text">
                                                                        <div className="nk-iv-wg2-amount ui-v2">Mining Rigs Income USD {FreePlanProfits}</div>
                                                                        <ul className="nk-iv-wg2-list">
                                                                            <li><span className="item-label">Free Profits</span><span className="item-value">{FreePlanProfits}</span></li>
                                                                            {/* <li><span className="item-label">No of Referrals</span><span className="item-value">0</span></li> */}
                                                                            <li className="total"><span className="item-label">Total Free Profit</span><span className="item-value">{FreePlanProfits}</span></li>
                                                                        </ul>
                                                                    </div>
                                                                    {/* WITHDRAWAL BUTTON SHOULD SHOIW AFTER & DAYS */}
                                                                    <div className="nk-iv-wg2-cta">
                                                                        <a className="btn btn-primary btn-lg btn-block" onClick={MinigWithdrawalfee}>Click to Withdraw</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }





                                            {/* mining plan countdown timer */}
                                            <div className="col-md-6 col-lg-4">
                                                <div className="nk-wg-card card card-bordered h-100">
                                                    <div className="card-inner h-100">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">Mining Countdown <em className="icon ni ni-info text-primary" />{calculateTime(miningCountDownTimer)} </h6>
                                                            </div>
                                                            <div className="nk-iv-wg2-text">
                                                                <div className="nk-iv-wg2-amount ui-v2">USD {mininigProfits}</div>
                                                                <ul className="nk-iv-wg2-list">
                                                                    <li><span className="item-label">Current Mining Profits</span><span className="item-value">{mininigProfits}</span></li>
                                                                    {/* <li><span className="item-label">No of Referrals</span><span className="item-value">0</span></li> */}
                                                                    <li className="total"><span className="item-label">Current Total Mining  Profit</span><span className="item-value">{mininigProfits}</span></li>
                                                                </ul>
                                                            </div>
                                                            {
                                                                userdetails.currentMining == null && (
                                                                    <div className="nk-iv-wg2-cta">
                                                                        <Link to="/mining" className="btn btn-light btn-lg btn-block">Select A Mining Plan</Link>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>








                                            <div className="col-md-12 col-lg-4">
                                                <div className="nk-wg-card card card-bordered h-100">
                                                    <div className="card-inner h-100">
                                                        <div className="nk-iv-wg2">
                                                            <div className="nk-iv-wg2-title">
                                                                <h6 className="title">My Investment</h6>
                                                            </div>


                                                            {userdetails.currentSubscription != null && (
                                                                <>
                                                                    <div className="nk-iv-wg2-text">
                                                                        <div className="nk-iv-wg2-amount ui-v2"> <span className="sub">$ {userdetails.currentSubscription.amount}</span> {userdetails.currentSubscription.currentPlan}</div>
                                                                        <ul className="nk-iv-wg2-list">
                                                                            This investment will end in {new Date(userdetails.currentSubscription.dueDate).toDateString()}                                          </ul>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {userdetails.currentSubscription == null && (
                                                                <div className="nk-iv-wg2-text">
                                                                    <div className="nk-iv-wg2-amount ui-v2">0 <span className="sub">0</span> Active</div>
                                                                    <ul className="nk-iv-wg2-list">
                                                                        No Active Investments!                                           </ul>
                                                                </div>
                                                            )}


                                                            <div className="nk-iv-wg2-cta">
                                                                <Link to="/dashboard" className="btn btn-light btn-lg btn-block">Current Investment</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="card card-bordered">
                                            <div className="nk-refwg">
                                                <div className="nk-refwg-invite card-inner">
                                                    <div className="nk-refwg-head g-3">
                                                        <div className="nk-refwg-title">
                                                            <h5 className="title">Refer Us &amp; Earn</h5>
                                                            <div className="title-sub">Use the bellow link to invite your friends.</div>
                                                        </div>
                                                        <div className="nk-refwg-action"><a className="btn btn-primary">Invite</a></div>
                                                    </div>
                                                    <div className="nk-refwg-url">
                                                        <div className="form-control-wrap">
                                                            <div className="form-clip clipboard-init" data-clipboard-target="#refUrl" data-success="Copied" data-text="Copy Link"><em className="clipboard-icon icon ni ni-copy" /> <span className="clipboard-text">Copy Link</span></div>
                                                            <div className="form-icon"><em className="icon ni ni-link-alt" /></div>

                                                            {loggedin && (
                                                                <input type="text" className="form-control copy-text" id="refUrl" defaultValue={`https://Crypto-mineclub.com/signup.html?referralid=${f.auth().currentUser.uid}`} />
                                                            )}

                                                            {/* ${f.auth().currentUser.uid} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="nk-refwg-stats card-inner bg-lighter">
                                                    <div className="nk-refwg-group g-3">
                                                        <div className="nk-refwg-name">
                                                            <h6 className="title">My Referral <em className="icon ni ni-info" data-toggle="tooltip" data-placement="right" title="Referral Informations" /></h6>
                                                        </div>
                                                        <div className="nk-refwg-info g-3">
                                                            <div className="nk-refwg-sub">
                                                                <div className="title">0</div>
                                                                <div className="sub-text">Total Joined</div>
                                                            </div>
                                                            <div className="nk-refwg-sub">
                                                                <div className="title">0</div>
                                                                <div className="sub-text">Referral Earn</div>
                                                            </div>
                                                        </div>
                                                        <div class="nk-refwg-more dropdown mt-n1 mr-n1">
                                                            <a class="btn btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                            <div class="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                                                <ul class="link-list-plain sm">
                                                                    <li><a >7 days</a></li>
                                                                    <li><a >15 Days</a></li>
                                                                    <li><a >30 Days</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-refwg-ck">
                                                        <canvas className="chart-refer-stats" id="refBarChart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* TradingView Widget BEGIN */}
                        {/* TradingView Widget BEGIN */}
                        <div className="tradingview-widget-container">
                            <div className="nk-refwg-title">
                                <h5 className="title text-center">Crypto-mineclub Trading Session</h5>
                                <div className="title-sub text-center">This area shows active Trade session for User: <span>{userdetails.firstname} </span></div>
                            </div>
                            <div className="tradingview-widget-container__widget" />
                            <div className="tradingview-widget-copyright"><a rel="noopener" target="_blank"><span className="blue-text text-center">Trading Session</span></a> No Active Trade</div>
                        </div>
                        {/* TradingView Widget END */}
                        {/* TradingView Widget END */}
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020 <a >Crypto-mineclub Investment Management Company</a></div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        {/*<li class="nav-item"><a class="nav-link" >Terms</a></li>*/}
                                        {/*<li class="nav-item"><a class="nav-link" >Privacy</a></li>*/}
                                        {/*<li class="nav-item"><a class="nav-link" >Help</a></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" title="Custome Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" ><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Dashboard