import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import Dashboard from "./Dashbord/Dashboard";
import Deposit from "./Dashbord/Deposit";
import Invest from "./Dashbord/Invest";
import Investmentplans from "./Dashbord/Investmentplans";
import Mining from "./Dashbord/Mining";
import Investments from "./Dashbord/Investments";
import Profile from "./Dashbord/Profile";
import Referral from "./Dashbord/Referral";
import Settings from "./Dashbord/Settings";
import Withdrawal from "./Dashbord/Withdrawal";
import Withdrawalhistory from "./Dashbord/Withdrawalhistory";
import Deposithistory from "./Dashbord/Deposithistory";
import Register from "./Register";
import Login from "./Login";
import AppContainer from "./AppContainer";
import ConfirmInvest from "./Dashbord/ConfirmInvest"
import Forgetpassword from "./Forgetpassword";
import "./App.css"


function App() {

    return (
        <GlobalState>
            {/* <ToastContainer className="foo" /> */}
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<AppContainer />} />
                    <Route path="/register/:refer" element={<Register />} />
                    <Route path="/forgetpassword" element={<Forgetpassword />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/deposit" element={<Deposit />} />
                    <Route path="/invest" element={<Deposithistory />} />
                    <Route path="/investmentplans" element={<Investmentplans />} />
                    <Route path="/confirminvestment" element={<ConfirmInvest />} />
                    <Route path="/investments" element={<Investments />} />
                    <Route path="/mining" element={<Mining />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/referral" element={<Referral />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/withdrawal" element={<Withdrawal />} />
                    <Route path="/withdrawalhistory" element={<Withdrawalhistory />} />

                </Routes>
            </BrowserRouter>
        </GlobalState>
    );
}

export default App;
