import React, { useContext, useState, useEffect, useRef } from "react";
import { BrowserRouter, Link, Route, Routes, useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ConfirmInvest from "./ConfirmInvest";
import Swal from 'sweetalert2'

function Referral() {
    const [{ userdetails, loggedin, tradingpair, selectedinvestment }, dispatch] = useContext(GlobalContext);
    const setnav = () => {
        // const a = document.querySelector(".toggle-nav")
        const b = document.querySelector(".nk-header-menu")
        // a.classList.toggle("active")
        b.classList.toggle("mobile-menu")
        b.classList.toggle("nk-header-active")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }

    const toggletrig = () => {
        const a = document.querySelector(".toggle-class")
        //const b = document.querySelector(".navbar")
        a.classList.toggle("active")
    }

    const showtopnav = () => {
        const b = document.querySelector(".dropdown-menu-s1")
        b.classList.toggle("showtopnav")
        b.classList.toggle("show")
        // b.classList.toggle("navbar-mobile")
        console.log("jnjnjininjnjnj")
    }
    const navigate = useNavigate();

    const setloggedin = (data) => {

        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };

    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)

        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    navigate("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .onSnapshot((function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloading(false)
                } else {
                    console.log("No such document!");
                }
            })
            )
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    return (
        <div>
            {/* <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
            <meta charSet="utf-8" />
            <meta name="author" content="Crypto-mineclub" />
            <meta name="description" content="A powerful and conceptual apps base dashboard template that especially build for developers and programmers." />
            <link rel="shortcut icon" href="../images/favicon.png" />
            <title>My Scheme / Plan - Referrals </title>
            <link rel="stylesheet" href="../assets/css/dashlite8162.css?ver=1.9.0" />
            <link id="skin-default" rel="stylesheet" href="../assets/css/theme8162.css?ver=1.9.0" /> */}
            <div className="nk-app-root">
                <div className="nk-wrap ">
                    <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                        <div className="container-xl wide-lg">
                            <div className="nk-header nk-header-fluid nk-header-fixed is-theme  nk-header-fixed">
                                <div className="container-xl wide-lg">
                                    <div className="nk-header-wrap">
                                        <div className="nk-menu-trigger mr-sm-2 d-lg-none" onClick={setnav}><a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-menu" /></a></div>
                                        <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                        <div className="nk-header-menu" data-content="headerNav">
                                            <div className="nk-header-mobile">
                                                <div className="nk-header-brand"><a href="./index.php" className="logo-link"><img className="logo-light logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="" /><img className="o logo-img" src="../../assets/images/logo.png" srcSet="../../assets/images/logo.png" alt="o" /><span className="nio-version" /></a></div>
                                                <div className="nk-menu-trigger mr-n2"><a onClick={setnav} href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em className="icon ni ni-arrow-left" /></a></div>
                                            </div>
                                            <ul className="nk-menu nk-menu-main">
                                                <li className="nk-menu-item"><Link to="/dashboard" className="nk-menu-link"><span className="nk-menu-text">Dashboard</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/deposit" className="nk-menu-link"><span className="nk-menu-text">Deposit</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/invest" className="nk-menu-link"><span className="nk-menu-text">Deposit History</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/investmentplans" className="nk-menu-link"><span className="nk-menu-text">Investments</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawal" className="nk-menu-link"><span className="nk-menu-text">Withdrawals</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/mining" className="nk-menu-link"><span className="nk-menu-text">Mining plans</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/withdrawalhistory" className="nk-menu-link"><span className="nk-menu-text">Withdrawal history</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/profile" className="nk-menu-link"><span className="nk-menu-text">Profile</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/referral" className="nk-menu-link"><span className="nk-menu-text">My Referrals</span></Link></li>
                                                <li className="nk-menu-item"><Link to="/settings" className="nk-menu-link"><span className="nk-menu-text">Settings</span></Link></li>
                                            </ul>
                                        </div>
                                        <div className="nk-header-tools">
                                            <ul className="nk-quick-nav">
                                                <li className="dropdown notification-dropdown">
                                                    <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                                        <div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s2">
                                                        <div className="dropdown-head"><span className="sub-title nk-dropdown-title">Notifications</span><a href="#">Mark All as Read</a></div>
                                                        <div className="dropdown-body">
                                                            <div className="nk-notification">
                                                                <table className="table table-ulogs">
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th className="tb-col-time">
                                                                                <span className="overline-title">Activity</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span className="overline-title">Details</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead><thead>
                                                                        <tr>
                                                                            <td className="tb-col-os">Authentication</td>
                                                                            <td className="tb-col-ip">
                                                                                <span className="sub-text">You logged in on Friday 15th of April 2022 10:12:41 AM</span>
                                                                            </td>
                                                                        </tr><tr>
                                                                        </tr></thead></table>
                                                            </div>
                                                            <div className="dropdown-foot center"><a href="#">View All</a></div>
                                                        </div>
                                                    </div></li>
                                                <li className="hide-mb-sm"><a href="#"><img href="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" /></a></li>
                                                <li className="dropdown user-dropdown order-sm-first">
                                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                        <div className="user-toggle">
                                                            <div className="user-avatar sm" onClick={showtopnav}><em className="icon ni ni-user-alt" /></div>
                                                            <div className="user-info d-none d-xl-block">
                                                                <div className="user-status user-status-verified">verified</div>
                                                                <div className="user-name dropdown-indicator">{userdetails.firstname}  {userdetails.lastname} </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                            <div className="user-card">
                                                                <div className="user-avatar"><span>OS</span></div>
                                                                <div className="user-info"><span className="lead-text">{userdetails.firstname} </span><span className="sub-text">{userdetails.email} </span></div>
                                                                <div className="user-action"><a className="btn btn-icon mr-n2" href="profile-setting.php"><em className="icon ni ni-setting" /></a></div>
                                                            </div>
                                                        </div>
                                                        <div className="dropdown-inner user-account-info">
                                                            <h6 className="overline-title-alt">Account Balance</h6>
                                                            <div className="user-balance">0 <small className="currency currency-usd">USD</small></div>
                                                            <Link to="/withdrawal"> <span>Withdraw Balance</span> <em className="icon ni ni-wallet-out" /></Link>
                                                        </div>
                                                        <div className="dropdown-inner">
                                                            {/* <ul className="link-list">
                                                                <li><Link to="/profile"> <em className="icon ni ni-user-alt" /><span>View Profile</span></Link></li>
                                                                <li><Link to="/settings"> <em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                                                                <li><Link to="/"> <em className="icon ni ni-activity-alt" /><span>Login Activity</span></Link></li>
                                                            </ul> */}
                                                        </div>
                                                        <div className="dropdown-inner">
                                                            <ul className="link-list">
                                                                <li><a onClick={showtopnav}><em className="icon ni ni-signout" /><span></span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content nk-content-lg nk-content-fluid">
                        <div className="container-xl wide-lg">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub"><span>My referrals</span></div>
                                            <div className="nk-block-between-md g-4">
                                                <div className="nk-block-head-content">
                                                    <h2 className="nk-block-title fw-normal">Your Network</h2>
                                                    <div className="nk-block-des">
                                                        <p>Here is your current referral Bonus available.</p>
                                                    </div>
                                                </div>
                                                <div className="nk-block-head-content">
                                                    <ul className="nk-block-tools gx-3">
                                                        <li><Link to="/withdrawal" className="btn btn-primary"><span>Withdraw</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block" /></Link></li>
                                                        <li><Link to="/investmentplans" className="btn btn-white btn-light"><span>Invest More</span> <em className="icon ni ni-arrow-long-right d-none d-sm-inline-block" /></Link></li>
                                                        {/* <li class="opt-menu-md dropdown">
                                      <a href="#" class="btn btn-white btn-light btn-icon" data-toggle="dropdown"><em class="icon ni ni-setting"></em></a>
                                      <div class="dropdown-menu dropdown-menu-right">
                                         <ul class="link-list-opt no-bdr">
                                            <li><a href="#"><em class="icon ni ni-coin-alt"></em><span>Curreny Settings</span></a></li>
                                            <li><a href="#"><em class="icon ni ni-notify"></em><span>Push Notification</span></a></li>
                                         </ul>
                                      </div>
                                   </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="card card-bordered">
                                            <div className="card-inner-group">
                                                <div className="card-inner">
                                                    <div className="row gy-gs">
                                                        <div className="col-lg-5">
                                                            <div className="nk-iv-wg3">
                                                                <div className="nk-iv-wg3-title">Total Bonus</div>
                                                                <div className="nk-iv-wg3-group  flex-lg-nowrap gx-4">
                                                                    <div className="nk-iv-wg3-sub">
                                                                        <div className="nk-iv-wg3-amount">
                                                                            <div className="number">{userdetails.balance} <small className="currency currency-usd">USD</small></div>
                                                                        </div>
                                                                        <div className="nk-iv-wg3-subtitle">Available</div>
                                                                    </div>
                                                                    <div className="nk-iv-wg3-sub">
                                                                        <span className="nk-iv-wg3-plus text-soft"><em className="icon ni ni-plus" /></span>
                                                                        <div className="nk-iv-wg3-amount">
                                                                            <div className="number-sm">0</div>
                                                                        </div>
                                                                        <div className="nk-iv-wg3-subtitle">Total Referred <em className="icon ni ni-info-fill" data-toggle="tooltip" data-placement="right" title="peopel you referred" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="nk-iv-wg3">
                                                                <div className="nk-iv-wg3-sub flex-grow-1 ml-md-3">
                                                                    <div className="nk-iv-wg3-ck">
                                                                        <canvas className="chart-profit" id="profitCM" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="card card-bordered">
                                                                <table style={{ overflow: 'auto' }} className="table table-ulogs">
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th className="tb-col-os">
                                                                                <span className="overline-title">Depositor</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span className="overline-title">Invested</span>
                                                                            </th>
                                                                            <th className="tb-col-ip">
                                                                                <span className="overline-title">💎 Earned</span>
                                                                            </th>
                                                                            <th className="tb-col-time">
                                                                                <span className="overline-title">Date</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-footer nk-footer-fluid bg-lighter">
                        <div className="container-xl wide-lg">
                            <div className="nk-footer-wrap">
                                <div className="nk-footer-copyright"> © 2020 . Template by <a href="#">Crypto-mineclub</a></div>
                                <div className="nk-footer-links">
                                    <ul className="nav nav-sm">
                                        {/* <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                       <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                       <li class="nav-item"><a class="nav-link" href="#">Help</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nk-sticky-toolbar">
                <li className="demo-settings"><a className="toggle tipinfo" data-target="settingPanel" href="#" title="Demo Settings"><em className="icon ni ni-setting-alt" /></a></li>
            </ul>
            <div className="nk-demo-panel toggle-slide toggle-slide-right" data-content="settingPanel" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                <div className="nk-demo-head">
                    <h6 className="mb-0">Preview Settings</h6>
                    <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2" data-target="settingPanel" href="#"><em className="icon ni ni-cross" /></a>
                </div>
                <div className="nk-opt-panel" data-simplebar>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Main UI Style</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="style" data-update="ui-default"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Default</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-clean"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Clean</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-shady"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Shady</span></span></div>
                            <div className="nk-opt-item only-text" data-key="style" data-update="ui-softy"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Softy</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-header">
                        <div className="nk-opt-set-title">Header Style</div>
                        <div className="nk-opt-list col-4x">
                            <div className="nk-opt-item active" data-key="header" data-update="is-light"><span className="nk-opt-item-bg is-light"><span className="bg-lighter" /></span><span className="nk-opt-item-name">White</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-default"><span className="nk-opt-item-bg is-light"><span className="bg-light" /></span><span className="nk-opt-item-name">Light</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-dark"><span className="nk-opt-item-bg"><span className="bg-dark" /></span><span className="nk-opt-item-name">Dark</span></div>
                            <div className="nk-opt-item" data-key="header" data-update="is-theme"><span className="nk-opt-item-bg"><span className="bg-theme" /></span><span className="nk-opt-item-name">Theme</span></div>
                        </div>
                        <div className="nk-opt-set-title">Header Option</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item only-text active" data-key="header_opt" data-update="is-regular"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Regular</span></span></div>
                            <div className="nk-opt-item only-text" data-key="header_opt" data-update="nk-header-fixed"><span className="nk-opt-item-bg"><span className="nk-opt-item-name">Fixed</span></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set nk-opt-set-skin">
                        <div className="nk-opt-set-title">Primary Skin</div>
                        <div className="nk-opt-list">
                            <div className="nk-opt-item active" data-key="skin" data-update="default"><span className="nk-opt-item-bg"><span className="skin-default" /></span><span className="nk-opt-item-name">Default</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="blue"><span className="nk-opt-item-bg"><span className="skin-blue" /></span><span className="nk-opt-item-name">Blue</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="egyptian"><span className="nk-opt-item-bg"><span className="skin-egyptian" /></span><span className="nk-opt-item-name">Egyptian</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="purple"><span className="nk-opt-item-bg"><span className="skin-purple" /></span><span className="nk-opt-item-name">Purple</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="green"><span className="nk-opt-item-bg"><span className="skin-green" /></span><span className="nk-opt-item-name">Green</span></div>
                            <div className="nk-opt-item" data-key="skin" data-update="red"><span className="nk-opt-item-bg"><span className="skin-red" /></span><span className="nk-opt-item-name">Red</span></div>
                        </div>
                    </div>
                    <div className="nk-opt-set">
                        <div className="nk-opt-set-title">Skin Mode</div>
                        <div className="nk-opt-list col-2x">
                            <div className="nk-opt-item active" data-key="mode" data-update="theme-light"><span className="nk-opt-item-bg is-light"><span className="theme-light" /></span><span className="nk-opt-item-name">Light Skin</span></div>
                            <div className="nk-opt-item disabled" data-key="mode" data-update="theme-dark"><span className="nk-opt-item-bg"><span className="theme-dark" /></span><span className="nk-opt-item-name">Dark Skin <small>(Soon)</small></span></div>
                        </div>
                    </div>
                    <div className="nk-opt-reset"><a href="#" className="reset-opt-setting">Reset Setting</a></div>
                </div>
            </div>
        </div>
    )
}

export default Referral